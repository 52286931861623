@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
}

body {
  padding: 2px;
  font-family: 'Nunito', sans-serif;
  border-style: solid;
}


/* NAVBAR STYLING */
.burger-wrapper {
cursor: pointer;
}
#meny {
  background: rgba(0, 0, 0, 0.901);
  width: 50rem;
  height: 100vh;
  position: fixed;
  right: 0;
  transition-timing-function: cubic-bezier(10,2,3,1);
  transform: translateX(50rem);
  top: 0;
  z-index: 0;
  transition: 0.5s;
}
#menyAvPaa {
  display: none;
}

#menyAvPaa:checked ~ #meny {
  transform: translateX(0rem);
  z-index: 250
}

#burger {
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 1rem;
  top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#burger > div {
  height: 2px;
  background-color: rgb(0, 0, 0);
  transition: 0.5s;
  z-index: 999;
}

#menyAvPaa:checked ~ #burger > div {
background-color: #fff;
}

#menyAvPaa:checked ~ #burger > div:nth-child(1) {
  transform: translateY(15px) rotate(45deg);
}
#menyAvPaa:checked ~ #burger > div:nth-child(2) {
  opacity: 0;
}
#menyAvPaa:checked ~ #burger > div:nth-child(3) {
  transform: translateY(-15px) rotate(-45deg);
}
